const lang = {
    'index':{
        'title':'系统登录',
        'form.user':'请输入用户名',
        'form.pass':'请输入密码',
        'form.login':'登录',
        'form.reset':'重置',
        'form.user-pass.tips':'请输入完整的账号密码',
        'tips.wait':'正在登录,请稍后..',
        'tips.succ':'登录成功,正在跳转..',
        'tips.fail':'登录失败[可能是账号或密码不正确]',
        'tips.version':'版本不正确'
    },
    'alertTips':{
        'html.span.tips':'建议使用最新谷歌浏览器,是否前往下载?',
        'html.el-checkbox.label':'不再提示',
        'html.el-button.clearView':'我已知晓',
        'html.el-button.toDonwload':'前去下载',
    },
};
export default lang;